import { defineStore } from "pinia";

export const useShowHideStore = defineStore({
  id: "showhide",
  state: () => ({
    toggledSelector: "",
  }),

  actions: {
    toggle(selector: string) {
      this.toggledSelector = selector;
    },
  },
});
