<template>
  <button
    class="button event-page__submit"
    type="submit"
    @click="
      (e) => {  
        if(!executeScript)    
        e.preventDefault();
        click(e as PointerEvent);
      }
    "
  >
    {{ websiteText("sv.event.register") }}
  </button>
  <GaEventScript
    v-if="executeScript"
    event-type="add_to_cart"
    fb-event-type="AddToCart"
    :botton="button"
    :script="script"
  ></GaEventScript>
</template>
<script lang="ts" setup>
  import { ref } from "vue";
  import { websiteText } from "@/mixins/websiteTexts";
  import GaEventScript from "../genaral/GaEventScript.vue";
  defineProps<{
    script: string;
  }>();
  const executeScript = ref<boolean>(false);
  const button = ref<HTMLButtonElement>();
  const click = (e: PointerEvent) => {
    button.value = e.target as HTMLButtonElement;
    executeScript.value = true;
  };
</script>
