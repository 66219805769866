<template>
  <div v-if="false"></div>
</template>
<script lang="ts" setup>
  import { api } from "@/api/apiBase";
  import { onMounted } from "vue";
  import type { ModelEvent } from "@/types/modelEventType";
  import type { CustomerDetails } from "@/types/checkoutModel";
  const props = defineProps<{
    script?: string;
    eventType?: string;
    botton?: HTMLButtonElement;
    paymentType?: string;
    transactionId?: string;
    fbEventType?: string;
    customerDetails?: any;
  }>();
  let cDetails: CustomerDetails | null = null;
  const postConversionEvent = async (eventId: string, eventName: string, scriptObject: any) => {
    await api
      .post<number>(`/conversion`, {
        event_id: eventId,
        event_name: eventName,
        event_source_url: window.location.href,
        custom_data: {
          value: scriptObject.ecommerce.value,
          currency: scriptObject.ecommerce.currency,
          content_name: document.title,
          content_ids: scriptObject.ecommerce.items.map((x: any) => x.item_id),
          content_type: "product_group",
        },
        user_data: {
          ...(cDetails && cDetails.email && { em: cDetails.email }),
          ...(cDetails && cDetails.firstName && { fn: cDetails.firstName }),
          ...(cDetails && cDetails.lastName && { ln: cDetails.lastName }),
          ...(cDetails && cDetails.phoneNumber && { ph: cDetails.phoneNumber }),
        },
      } as ModelEvent)
      .then((r) => {
        console.log(r.data);
      })
      .catch((error) => {
        if (error.message) {
          console.log(error.message);
        }
      });
  };
  const handleFb = async (scriptObject: any) => {
    console.log("FB server: ", props.fbEventType);
    //server side tracking
    await postConversionEvent(rdm, props.fbEventType as string, scriptObject);
  };

  const handleGa = (scriptObject: any) => {
    const dataLayer = (window as any).dataLayer || [];
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      ...scriptObject,
      event: props.eventType,
      ecommerce: {
        ...scriptObject.ecommerce,
        fb_event_id: rdm,
        ...(props.paymentType && { payment_type: props.paymentType }),
        ...(props.transactionId && { transaction_id: props.transactionId }),
      },
    });
  };
  let rdm: string;
  onMounted(async () => {
    if (props.customerDetails && typeof props.customerDetails == "string") {
      cDetails = JSON.parse(props.customerDetails) as CustomerDetails;
    } else if (props.customerDetails) {
      cDetails = props.customerDetails as CustomerDetails;
    }
    let pref = "event";
    if (props.fbEventType) {
      pref = props.fbEventType.toLowerCase();
    }
    if (props.transactionId) {
      rdm = pref + "_" + props.transactionId;
    } else {
      rdm = pref + "_" + (Math.random() + 1).toString(36).substring(2);
    }
    let scriptObject: any;

    if (props.script) {
      scriptObject = JSON.parse(props.script);
    }
    if (scriptObject) {
      handleGa(scriptObject);
      if (window.Cookiebot && window.Cookiebot.consent && window.Cookiebot.consent.marketing) {
        await handleFb(scriptObject);
      } else {
        window.addEventListener(
          "CookiebotOnAccept",
          async function () {
            if (window.Cookiebot.consent.marketing) {
              await handleFb(scriptObject);
            }
          },
          false
        );
      }
      if (props.botton) {
        props.botton.click();
      }
    }
  });
</script>
