<template>
  <div class="sub-nav-block">
    <div class="sub-nav-block__content">
      <div class="sub-nav-block__content-wrap">
        <div class="sub-nav-block__content-links">
          <TransitionGroup
            id="sub-page-nav"
            :class="['link-list-column', 'sub-nav-block__content-list']"
            name="list"
            tag="ul"
            role="menu"
            aria-labelledby="sub-page-nav-button"
            @enter="enter"
            @after-enter="afterEnter"
            @leave="leave"
          >
            <li
              v-for="(item, index) in nav && nav.list && nav.list.slice(0, 5)"
              :key="`list-${index}`"
              class="sub-nav-block__content-list-item link-list-column__item"
            >
              <a class="link-list-column__item-link sub-nav-block__item-link" :href="item.url">
                {{ item.title ?? item.name }}</a
              >
            </li>
            <li
              v-if="nav && nav.contactPage && nav.contactPage.url"
              :key="`list-contactpage`"
              :class="[
                { 'sub-nav-block__content-list-item--single': !nav.list.length },
                'sub-nav-block__content-list-item',
                'sub-nav-block__content-list-item--contact',
                'link-list-column__item',
              ]"
            >
              <a class="link-list-column__item-link sub-nav-block__item-link" :href="nav.contactPage.url">
                {{ nav.contactPage.name }}</a
              >
            </li>
            <li
              v-for="(item, index) in nav && nav.list && (toggle ? nav.list.slice(5, nav.list.length) : [])"
              :key="`list-${index}`"
              class="sub-nav-block__content-list-item link-list-column__item"
            >
              <a class="link-list-column__item-link sub-nav-block__item-link" :href="item.url">
                {{ item.title ?? item.name }}</a
              >
            </li>
          </TransitionGroup>
        </div>
      </div>
      <div v-if="nav && nav.list && nav.list.length > 5" class="sub-nav-block__button-wrap">
        <button
          id="sub-page-nav-button"
          aria-controls="sub-page-nav"
          :aria-expanded="toggle"
          type="button"
          :class="[{ 'sub-nav-block__button--expand': toggle }, 'button', 'sub-nav-block__button']"
          @click="toggla()"
        >
          {{ toggle ? websiteText("sv.menu.subnav.showless") : websiteText("sv.menu.subnav.showall") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { onMounted, ref } from "vue";
  import { websiteText } from "@/mixins/websiteTexts";
  import type { ISubNav } from "@/types/subNavType";
  const props = defineProps<{
    navigation: string;
  }>();
  const nav = ref<ISubNav | null>(null);
  const toggla = () => {
    toggle.value = !toggle.value;
  };
  const toggle = ref<boolean>(false);
  onMounted(() => {
    if (props.navigation) {
      nav.value = JSON.parse(props.navigation);
    }
  });

  function enter(element: any) {
    const width = getComputedStyle(element).width;

    element.style.width = width;
    element.style.position = "absolute";
    element.style.visibility = "hidden";
    element.style.height = "auto";

    const height = getComputedStyle(element).height;

    element.style.width = "";
    element.style.position = "";
    element.style.visibility = "";
    element.style.height = "0";
    getComputedStyle(element).height;
    requestAnimationFrame(() => {
      element.style.height = height;
    });
  }
  function afterEnter(element: any) {
    element.style.height = "auto";
  }
  function leave(element: any) {
    const height = getComputedStyle(element).height;

    element.style.height = height;
    getComputedStyle(element).height;
    requestAnimationFrame(() => {
      element.style.height = "0";
    });
  }
</script>
<style scoped lang="scss">
  @use '../../../css/abstracts' as *;
  .sub-nav-block {
    margin: to-rem(32px 0 32px 0);
    @include medium {
      margin-top: 0;
    }
    &__button-wrap {
      margin-top: to-rem(16px);
      display: flex;
      justify-content: center;
    }
    &__button {
      &::after {
        transition: transform 0.4s ease-in-out;
      }
      &::after {
        transform: rotate(90deg);
      }
      &--expand {
        &::after {
          transform: rotate(-90deg);
        }
      }
    }
    &__content {
      &-wrap {
        background-color: $color-white;
        border-radius: 20px 0 20px 0;
        padding-bottom: to-rem(16px);
        padding-top: to-rem(16px);
        width: 100%;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        @include medium {
          border-radius: 0;
        }
      }
      &-title {
        margin-top: 0;
        margin: to-rem(0 16px 0 0);
      }

      &-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &-item {
          margin: 0 16px 0 0;
          width: 100%;
          padding-left: 5px;
          padding-right: 5px;
          &--contact {
            background-color: $background-light-green;
            border-bottom: none !important;
            margin-right: 0 !important;
          }
          &--single {
            margin-left: auto;
            border-top: none !important;
          }
          @include small {
            width: calc(1 / 2 * 100% - (1 - 1 / 2) * 16px);
            &:nth-last-child(-n + 2) {
              border-bottom: thin solid $color-mid-grey;
            }
          }
          @include medium {
            width: calc(1 / 3 * 100% - (1 - 1 / 3) * 24px);

            &:nth-last-child(-n + 3) {
              border-bottom: thin solid $color-mid-grey;
            }
          }

          &:nth-child(n) {
            margin-right: 0;
          }
          @include small {
            &:nth-child(n) {
              margin-right: 16px;
            }
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
          @include medium {
            &:nth-child(n) {
              margin-right: 24px;
            }
            &:nth-child(3n) {
              margin-right: 0;
            }
          }
        }
      }

      &-links {
        width: 100%;
        margin: auto;
        max-width: $container-max-width;
        padding: to-rem(0 24px 0 24px);
        @include medium {
          padding: to-rem(0 $padding-left-right);
        }
        &-main {
          width: 100%;
          margin-top: to-rem(16px);
          @include medium {
            width: auto;

            margin-top: 0;
          }
        }
      }
    }
    &__item-link {
      padding: to-rem(12px 0);
    }
  }

  .list-enter-active,
  .list-leave-active {
    transition: height 0.4s ease-in-out;
    overflow: hidden;
  }
</style>
