<script setup lang="ts">
  import { useSearchStore } from "@/store/searchStore";
  import debounce from "lodash/debounce";
  import SearchResult from "./SearchResult.vue";
  import { computed, onMounted, ref } from "vue";
  import { useCookies } from "vue3-cookies";
  import useDetectOutsideClick from "@/helpers/useDetectOutsideClick";
  const search = useSearchStore();
  defineProps<{
    searchText?: string;
    searchPlaceholder?: string;
    searchHistory?: string;
  }>();
  const searchUrl = computed(() => {
    return window.__litium.quickSearchUrl + (search.getQuery.length > 0 ? `?q=${search.getQuery}` : "");
  });
  const inputValue = ref<string>("");
  const mySearches = ref<Array<string>>([]);
  const { cookies } = useCookies();
  const inFocus = ref<boolean>(false);
  const componentRef = ref();
  useDetectOutsideClick(componentRef, () => {
    if (inFocus.value) inFocus.value = false;
  });
  onMounted(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("g_County")) {
      g_county.value = urlParams.get("g_County");
    }
    if (urlParams.has("q")) {
      const query = urlParams.get("q");
      if (query) {
        inputValue.value = query; // decodeURIComponent(query);

        const searchList = cookies.get("svMySearch") ? JSON.parse(cookies.get("svMySearch")) : [];
        if (!searchList.includes(query)) {
          if (searchList.length >= 5) {
            searchList.pop();
          }
          searchList.unshift(query);
          cookies.set("svMySearch", JSON.stringify(searchList));
        }
      }
    }
    if (cookies.get("svMySearch")) {
      mySearches.value = JSON.parse(cookies.get("svMySearch"));
    }
  });
  const debouncedQuery = debounce((val) => search.getSearchResult(val), 400);
  const g_county = ref<string | null>(null);
</script>

<template>
  <div ref="componentRef" class="search">
    <form role="search" method="get" :action="searchUrl" class="search__form">     
      <input v-if="g_county" type="hidden" name="g_County" :value="g_county" />
      <input
        :value="inputValue"
        type="search"
        role="searchbox"
        class="search__form-input"
        :placeholder="searchPlaceholder"
        autocomplete="off"
        name="q"
        @input="
          (e) => {
            inputValue = (e.target as HTMLInputElement).value;
            debouncedQuery((e.target as HTMLInputElement).value);
           
        }"
        @keydown="
          (event) => {
            if (event.key === 'Enter' && search.getSelectedItem !== -1) event.preventDefault();
            search.handleKeyDown(event, { searchUrl });
          }
        "
        @focus="inFocus = true"
      /><button class="search__form-button">
        <span class="search__form-button-text">{{ searchText }}</span>
      </button>
    </form>
    <SearchResult
      v-if="inFocus"
      :search-url="searchUrl"
      :result="search.getResult"
      :my-search-label="searchHistory"
      :my-searches="mySearches"
      :selected-item="search.getSelectedItem"
    ></SearchResult>
  </div>
</template>
<style lang="scss" scoped>
  @use '../../../css/abstracts' as *;
  .search {
    position: relative;
    border: 1px solid $color-text;
    border-radius: 8px 20px 20px 8px;

    &__form {
      height: to-rem(38px);
      display: flex;
      @include medium {
        min-width: to-rem(400px);
      }

      &-input {
        border: none;
        flex: auto;
        border-radius: 8px;
        padding: to-rem(0 15px);
        border-right: none;
        flex: auto;
      }
      &-button {
        border: none;
        min-width: to-rem(50px);
        border-radius: 50px;
        cursor: pointer;
        background-color: transparent;
        display: flex;
        align-items: center;
        padding: to-rem(0 16px);
        &::after {
          margin-left: to-rem(8px);
          content: "";
          display: inline-block;
          @include icon;
          @include icon--magnifier;
        }

        background-color: $color-text;
        color: $color-white;
        @include medium {
          min-width: to-rem(70px);
        }
      }
    }
  }
</style>
