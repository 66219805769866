import axios, { AxiosInstance } from "axios";

const headers = {
  "content-type": "application/json",
  "litium-request-context": JSON.stringify(window.__litium.requestContext),
};

export const api: AxiosInstance = axios.create({
  baseURL: `https://${window.location.host}`,
  headers
});