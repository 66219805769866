import { defineStore } from "pinia";
import { api } from "@/api/apiBase";
import type { ISearchResponse } from "@/types/searchResponseType";

export const useSearchStore = defineStore({
  id: "search",
  state: () => ({
    searchresults: [] as ISearchResponse["response"],
    loading: false,
    error: null,
    query: "",
    selectedItem: -1,
  }),
  getters: {
    getResult: (state) => state.searchresults,
    getQuery: (state) => state.query,
    getSelectedItem: (state) => state.selectedItem,
  },
  actions: {
    async getSearchResult(q: string) {
      //   this.query
      this.query = q;
      this.searchresults = [];
      this.loading = true;
      return await api
        .post<ISearchResponse["response"]>(`/quickSearch`, JSON.stringify(q))
        .then((res) => {
          this.searchresults = res.data;
        })
        .catch((error) => (this.error = error))
        .finally(() => (this.loading = false));
    },
    handleKeyDown(event: KeyboardEvent, opt: any) {
      if (!this.searchresults || !this.searchresults.length) {
        return;
      }
      const max = this.searchresults.length - 1,
        clip = (index: number) => (index < 0 ? max : index > max ? 0 : index);
      switch (event.key) {
        case "ArrowUp":
          this.selectedItem = clip(this.selectedItem - 1);
          break;
        case "ArrowDown":
          this.selectedItem = clip(this.selectedItem + 1);
          break;
        case "Enter": {
          const selectedObject = this.searchresults[this.selectedItem];
          if (selectedObject && selectedObject.url) {
            location.href = selectedObject.url;
          } else {
            location.href = opt.searchUrl;
          }
          break;
        }
        default:
          break;
      }
    },
  },
});
