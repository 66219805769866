/**
 * @class ReadspeakerButton
 */

const READSPAKER_DIV = "readspeaker";
const READSPEAKER_BUTTON = ".readspeaker-listen-button";

class ReadspeakerButton {
  binds() {
    const handleButtonClick = (event: Event) => {
      event.stopPropagation();
      event.preventDefault();

      const isOpen =
        document.getElementById(READSPAKER_DIV)?.offsetParent !== null;

      if (isOpen) {
        ReadSpeaker.q(function () {
          if (rspkr.ui.getActivePlayer()) {
            rspkr.ui.getActivePlayer().close();
          }
        });
      } else {
        readpage((event.target as HTMLAnchorElement).href, READSPAKER_DIV);
      }
    };

    const readspeakerButtons = document.querySelectorAll(READSPEAKER_BUTTON);

    Array.from(readspeakerButtons).forEach((element) =>
      (element as HTMLAnchorElement).addEventListener(
        "click",
        handleButtonClick
      )
    );
  }
}
export const { binds } = new ReadspeakerButton();
