<template>
  <div :class="buttonClass ? buttonClass : 'show-hide'">
    <button
      type="button"
      :class="`button button${
        (query && !toggled) ||
        (selector && selectorDependent && toggledSelector !== selector) ||
        (selector && !selectorDependent && toggled)
          ? '--show'
          : '--hide'
      }`"
      @click="toggle()"
    >
      {{ toggled ? textClose : text }}
    </button>
  </div>
</template>
<script lang="ts" setup>
  import { ref, onMounted } from "vue";
  import { useShowHideStore } from "@/store/showHideStore";
  import { storeToRefs } from "pinia";
  const props = defineProps<{
    selector?: string;
    text: string;
    textClose: string;
    hideClass?: string;
    buttonClass?: string;
    selectorDependent?: string;
    default?: string;
    query?: string;
  }>();
  const toggled = ref<boolean>(false);
  const showHideStore = useShowHideStore();
  const { toggledSelector } = storeToRefs(showHideStore);
  const setElementClass = () => {
    if (props.selector) {
      const elements = document.querySelectorAll(props.selector);
      if (elements) elements.forEach((x) => x.classList.toggle(props.hideClass ? props.hideClass : "hide"));
      if (props.selectorDependent) {
        const elements = document.querySelectorAll(props.selectorDependent);
        if (elements) elements.forEach((x) => x.classList.toggle(props.hideClass ? props.hideClass : "hide"));
      }
    }
  };
  const toggle = () => {
    if (props.selector) {
      if (props.selectorDependent && props.selector !== toggledSelector.value) {
        showHideStore.toggle(props.selector);
        setElementClass();
      } else if (!props.selectorDependent) {
        toggled.value = !toggled.value;
        setElementClass();
      }
    } else if (props.query) {
      const searchParams = new URLSearchParams(window.location.search);
      if (!props.default) {
        searchParams.set(props.query, "true");
      } else {
        searchParams.delete(props.query);
      }
      window.location.search = searchParams.toString();
    }
  };
  onMounted(() => {
    if (props.default && props.selector) {
      showHideStore.toggle(props.selector);
    } else if (props.query) {
      const searchParams = new URLSearchParams(window.location.search);
      const q = searchParams.get(props.query);
      if ((q === "true" && !props.default) || (!q && props.default)) {
        toggled.value = true;
      }
    }
  });
</script>
<style lang="scss">
  @use '../../../css/abstracts' as *;
  .show-hide {
    width: 100%;
    margin-top: to-rem(24px);
    button {
      width: 100%;
    }
    @include medium {
      display: none;
      &--both {
        display: block;
      }
    }
    &--both {
      display: block;
      width: auto;
      margin-right: to-rem(16px);
      .button {
        border-radius: 4px;

        &::after {
          content: none;
        }
        &--show {
          background-color: transparent;
          color: $color-text;
          text-decoration: underline;
        }
      }
    }
  }
</style>
