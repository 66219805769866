<template>
  <div class="news__row-author">
    <div class="news__author-container">
      <img v-if="imageurl" :alt="`${authorfirstname} ${authorlastname}`" :src="imageurl" class="news__author-img" />
      <img v-else alt="Inget foto" class="news__author-img" :src="dummy" />
    </div>

    <div class="news__author-info">
      <p class="news__author-title news__author-title--truncate">{{ authortitle }}</p>
      <p class="news__author-name">{{ authorfirstname }} {{ authorlastname }}</p>
      <p v-if="phone" class="news__author-com">
        <span>{{ websiteText("sv.contactpage.phone") }}:</span> {{ phone }}
      </p>
      <p v-if="email" class="news__author-com">
        <span>{{ websiteText("sv.contactpage.email") }}:</span> {{ email }}
      </p>
      <transition name="contact" @enter="enter" @after-enter="afterEnter" @leave="leave">
        <div v-if="open" class="news__author-description-container">
          <p>{{ authordescription }}</p>
        </div></transition
      >
      <p
        v-if="authordescription"
        :class="[{ 'news__author-link--open': open }, 'news__author-link']"
        @click="toggleDescription"
      >
        {{ !open ? websiteText("sv.contacts.card.readmore") : websiteText("sv.contacts.card.readless") }}
        {{ authorfirstname }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref } from "vue";
  import { websiteText } from "@/mixins/websiteTexts";
  const dummy = "/ui/jpg/no-photo.jpg";

  defineProps<{
    imageurl?: string;
    authortitle?: string;
    authorfirstname: string;
    authorlastname: string;
    authordescription?: string;
    phone?: string;
    email?: string;
  }>();
  function enter(element: any) {
    const width = getComputedStyle(element).width;

    element.style.width = width;
    element.style.position = "absolute";
    element.style.visibility = "hidden";
    element.style.height = "auto";

    const height = getComputedStyle(element).height;

    element.style.width = "";
    element.style.position = "";
    element.style.visibility = "";
    element.style.height = "0";
    getComputedStyle(element).height;
    requestAnimationFrame(() => {
      element.style.height = height;
    });
  }
  function afterEnter(element: any) {
    element.style.height = "auto";
  }
  function leave(element: any) {
    const height = getComputedStyle(element).height;

    element.style.height = height;
    getComputedStyle(element).height;
    requestAnimationFrame(() => {
      element.style.height = "0";
    });
  }
  const open = ref<boolean>(false);

  const toggleDescription = () => {
    open.value = !open.value;
  };
</script>
