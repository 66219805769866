export function websiteText(text: string, extraValues?: string[]) {
  const websiteStrings = window.__litium.translation;
  const textId = text.toLowerCase();

  if (textId === null || textId === "") {
    return "Nothing to translate";
  }

  if (websiteStrings === undefined || websiteStrings === null) {
    return "WebsiteStrings not found";
  }

  if (websiteStrings[textId] === undefined && extraValues) {
    return textId + " (" + extraValues.join(",") + ")";
  }

  if (websiteStrings[textId] === undefined) {
    return textId;
  }

  const websiteString: string = websiteStrings[textId];

  if (extraValues) {
    return websiteString.replace(/{(\d+)}/g, (match, num) => {
      return typeof extraValues[num] !== "undefined" ? extraValues[num] : match;
    });
  }

  return websiteString;
}
