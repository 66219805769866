import { defineStore } from "pinia";
import { api } from "@/api/filterApi";
import { websiteText } from "@/mixins/websiteTexts";
import { FacetFilter, FilterResponse, Option, SubNavigation, SortCriteria } from "@/types/filterType";
import {
  getSlectedDistanceFilter,
  getSlectedEventFilter,
  getSlectedCountyFilter,
  getSlectedStartDateFilter,
  getSlecteCityFilter,
  getSlectedCategoryFilter,
} from "@/helpers/filterHelper";
export const useFilterStore = defineStore({
  id: "filter",
  state: () => ({
    selectedDistanceFilter: getSlectedDistanceFilter(),
    distanceFilter: {} as FacetFilter,
    selectedEventFilter: getSlectedEventFilter(),
    eventFilter: {} as FacetFilter,
    selectedCountyFilter: getSlectedCountyFilter(),
    countyFilter: {} as FacetFilter,
    selectedCityFilter: getSlecteCityFilter(),
    cityFilter: {} as FacetFilter,
    selectedStartDateFilter: getSlectedStartDateFilter(),
    startDateFilter: {} as FacetFilter,
    selectedCategoryFilter: getSlectedCategoryFilter(),
    categoryFilter: {} as FacetFilter,
    categories: {} as SubNavigation | undefined,
    selectedCategory: "" as string | undefined,
    sortItems: [] as SortCriteria["sortItems"],
  }),

  actions: {
    async setFilterData() {
      const response = await api.get("/api/productFilter" + window.location.search);

      const filterResponse = (await response.data) as FilterResponse;
      console.log(filterResponse);
      this.sortItems = filterResponse.sortCriteria ? filterResponse.sortCriteria.sortItems : [];
      this.categories = filterResponse.subNavigation;
      if (this.categories && this.categories.url) this.selectedCategory = this.categories.url;
      this.categoryFilter = filterResponse.facetFilters.find((x) => x.id === "category") as FacetFilter;

      /*
      if (this.categoryFilter) {
        this.categoryFilter.options.sort((a, b) => {
          return a.label.localeCompare(b.label, "sv");
        });
      }
*/
      this.distanceFilter = filterResponse.facetFilters.find((x) => x.id === "g_DistanceCourse") as FacetFilter;
      if (this.distanceFilter)
        this.distanceFilter.options.unshift({
          id: "",
          label: websiteText("sv.filter.alltext"),
        } as Option);

      this.eventFilter = filterResponse.facetFilters.find((x) => x.id === "g_EventType") as FacetFilter;
      if (this.eventFilter) this.eventFilter.options.unshift({ id: "", label: "Alla" } as Option);

      this.countyFilter = filterResponse.facetFilters.find((x) => x.id === "g_County") as FacetFilter;

      if (this.countyFilter) {
        this.countyFilter.options.sort((a, b) => {
          return a.label.localeCompare(b.label, "sv");
        });
      }

      if (this.countyFilter) {
        this.countyFilter.options.unshift({
          id: "distans",
          label: websiteText("sv.filter.remote.selecttext"),
        } as Option);
        this.countyFilter.options.unshift({
          id: "",
          label: websiteText("sv.filter.county.selecttext"),
        } as Option);
      }
      
      const cityFilter = filterResponse.facetFilters.find((x) => x.id === "g_PremisesPostalCity") as FacetFilter;
      const municipalityFilter = filterResponse.facetFilters.find((x) => x.id === "g_Municipality") as FacetFilter

      if (cityFilter && municipalityFilter) {
        municipalityFilter.options.forEach(option => {
          if (!cityFilter.options.some(existingOption => existingOption.id === option.id)) {
            cityFilter.options.push(option);
          }
        });
      }

      if (cityFilter) {
      
        cityFilter.options = cityFilter.options.filter((o) => o.quantity > 0 && o.id != "").sort((a, b) => {        
          return a.label.localeCompare(b.label, "sv")
        });

        this.cityFilter = cityFilter;
        this.cityFilter.options.unshift({
          id: "",
          label: websiteText("sv.filter.city.selecttext"),
        } as Option);
      }

      this.startDateFilter = filterResponse.facetFilters.find((x) => x.id === "StartDate") as FacetFilter;
      if (this.startDateFilter)
        this.startDateFilter.options.unshift({
          id: "",
          label: websiteText("sv.filter.date.selecttext"),
        } as Option);
    },
  },
});
