export function getSlectedDistanceFilter(): string {
  const searchParams = new URLSearchParams(window.location.search);
  const value = searchParams.get("g_DistanceCourse");
  return value ? value : "";
}

export function getSlectedEventFilter(): string {
  const searchParams = new URLSearchParams(window.location.search);
  const value = searchParams.get("g_EventType");
  return value ? value : "";
}

export function getSlectedCountyFilter(): string[] {
  const searchParams = new URLSearchParams(window.location.search);
  const counties = searchParams.getAll("g_County");
  const distanceValue = searchParams.get("g_DistanceCourse");
  if (distanceValue === "True") {
    return counties.length ? ["remote"] : [];
  }
  return counties;
}

export function getSlectedStartDateFilter(): string {
  const searchParams = new URLSearchParams(window.location.search);
  const value = searchParams.get("StartDate");
  return value ? value : "";
}

export function getSlecteCityFilter(): string[] {
  const searchParams = new URLSearchParams(window.location.search);
  const cities = searchParams.getAll("g_PremisesPostalCity");
  const distanceValue = searchParams.get("g_DistanceCourse");
  if (distanceValue === "True") {
    return cities.length ? cities : [];
  }
  return cities;
}

export function getSlectedCategoryFilter(): string[] {
  const searchParams = new URLSearchParams(window.location.search);
  const value = searchParams.getAll("category");
  return value ? value : [];
}
