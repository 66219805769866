<script lang="ts" setup>
  import { websiteText } from "@/mixins/websiteTexts";
  import type { ISearchResponse, ISearchResponseItem } from "@/types/searchResponseType";
  defineProps<{
    result: ISearchResponse["response"];
    searchUrl: string;
    mySearchLabel?: string;
    mySearches: Array<string>;
    selectedItem: number;
  }>();
  const sameCategory = (item: ISearchResponseItem, index: number, array: ISearchResponse["response"]) =>
    (index > 0 && array[index - 1].category === array[index].category) || item.showAll;

  const onClickHistory = (e: MouseEvent) => {
    location.href = window.__litium.quickSearchUrl + "?q=" + (e.target as HTMLElement).innerText;
  };

  const dateString = (date: string) => {
    const dateDate: Date = new Date(date);
    if (dateDate.getFullYear() == 1) {
      return websiteText("sv.event.nostartdate");
    }
    return dateDate.toISOString().split("T")[0];
  };
</script>

<template>
  <div v-if="(result && result.length > 0) || (mySearches && mySearches.length > 0)" class="search-result">
    <ul class="search-result__list">
      <template v-for="(item, index) of result" :key="index">
        <li
          v-if="!sameCategory(item, index, result) && item.category !== 'NoHit'"
          class="search-result__list-item search-result__list-item--header"
        >
          {{ item.category }}
        </li>

        <li :class="[selectedItem === index ? 'search-result__list-item--selected' : '', 'search-result__list-item']">
          <a
            :class="[
              item.showAll
                ? 'search-result__list-item-link--show-all'
                : !item.url
                ? 'search-result__list-item-link--disabled'
                : '',
              'search-result__list-item-link',
            ]"
            :href="item.showAll ? searchUrl : item.url"
          >
            <!-- <div v-if="item.hasImage && item.imageSource" class="search-result__list-item-image">
              <img :alt="item.name" :src="item.imageSource" />
            </div> -->
            <div class="search-result__list-item-name search-result__list-item-name--truncate">{{ item.name }}</div>
            <div v-if="!item.showAll && item.isEvent" class="search-result__list-item-info">
              <span v-if="item.city || item.isDistance" class="search-result__list-item-city">{{
                item.isDistance ? websiteText("sv.event.remote") : item.city
              }}</span
              ><span class="search-result__list-item-date">
                  {{ item.startDate ? dateString(item.startDate) : websiteText("sv.event.nostartdateset") }}
                </span>
            </div>
          </a>
        </li>
      </template>
      <template v-if="!result.length || (result.length === 1 && result[0].category === 'NoHit')">
        <li
          v-if="mySearches.length > 0"
          class="search-result__prev-search-list-item search-result__prev-search-list-item--header"
        >
          {{ mySearchLabel ? mySearchLabel : "Din sökhistorik" }}
        </li>
        <li
          v-for="(val, index) in mySearches"
          :key="index"
          class="search-result__prev-search-list-item"
          @click="(e) => onClickHistory(e)"
        >
          {{ val }}
        </li>
      </template>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  @use '../../../css/abstracts' as *;
  .search-result {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    z-index: 4;
    background-color: $color-white;
    color: $color-text;
    width: 100%;
    padding: to-rem(16px 8px);

    border: 1px solid $color-border-gray;
    border-radius: 0px 40px;
    &__prev-search {
      &-list {
        &-item {
          padding: to-rem(5px 0);
          letter-spacing: 0.02em;
          cursor: pointer;
          &--header {
            font-weight: 700;
            cursor: initial;
          }
        }
      }
    }
    &__list {
      list-style: none;
      margin: 0;
      padding: 0;
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      padding: to-rem(0 8px);
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: $color-background-main;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $color-dark-grey;
        border-radius: 5px;
      }
      &-item {
        margin: 0;
        border-bottom: thin solid $color-light-grey;
        &:last-child {
          border-bottom: none;
        }
        &--selected {
          background-color: $color-light-grey;
        }
        &--header {
          border-bottom: 2px solid $color-text;
          font-weight: 700;
          letter-spacing: 0.02em;
          margin: to-rem(16px 0);
        }
        &-link {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          padding: to-rem(10px 3px);
          letter-spacing: 0.02em;
          &:hover {
            background-color: $color-light-grey;
          }
          &--show-all {
            line-height: 1;
            margin-top: to-rem(16px);
            background-color: $color-text;
            color: $color-white;
            display: inline-block;
            min-height: to-rem(44px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-decoration: none;
            border-radius: 100px;
            min-width: to-rem(100px);
            padding: to-rem(0 18px);
            &:hover {
              background-color: $color-text;
            }
            &::after {
              content: "";
              @include icon--arrow-white;
              @include icon;
              margin-left: to-rem(12px);
            }
          }
        }
        &-image {
          margin-right: to-rem(5px);
        }
        &-name {
          flex: auto;
          &--truncate {
            display: block;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 !important;
            line-height: var(--line-height, 1.25) !important;
            height: calc(1em * var(--line-height, 1.25) * 1);
            @include small {
              line-height: var(--line-height, 1.5) !important;
              height: calc(1em * var(--line-height, 1.5) * 1);
            }
            -webkit-line-clamp: 1;
          }
        }
        &-info {
          flex-basis: 100%;
          display: flex;
          justify-content: space-between;
          font-size: to-rem(13px);
          margin-top: to-rem(10px);
        }
        &-city {
          &::before {
            @include icon--location;
          }
        }
        &-date {
          &::before {
            @include icon--calendar;
          }
        }
        &-date,
        &-city {
          display: flex;
          align-items: center;
          line-height: 1;
          &::before {
            content: "";
            display: inline-block;
            @include icon--medium-small;
            margin-right: to-rem(5px);
          }
        }
      }
    }
  }
</style>
