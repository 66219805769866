import { createApp, defineAsyncComponent } from "vue";
import "../css/site.scss";
import HeaderSearch from "@/components/search/QuickSearch.vue";
import SearchPageSearch from "@/components/search/SearchPageSearch.vue";
import AuthorInfo from "@/components/author/AuthorInfo.vue";
import SubNavBlock from "@/components/pages/SubNavBlock.vue";
import ShowHide from "@/components/ui/ShowHide.vue";
import GaEventScript from "./components/genaral/GaEventScript.vue";
import RegisterButton from "./components/ui/RegisterButton.vue";
import { createPinia } from "pinia";
import "../js/menu.js";
import { binds } from "../js/readToSpeakButton";
binds();
import.meta.glob(["@/assets/icons/**"]);
import.meta.glob(["@/assets/img/**"]);

//header components
const RootComponentHeaderSearch = {
  components: {
    HeaderSearch,
  },
};

const RootComponentMain = {
  components: {
    SelectList: defineAsyncComponent(() => import("@/components/pages/SelectList.vue")),
    CategoriesList: defineAsyncComponent(() => import("@/components/pages/CategoriesList.vue")),
    CheckoutPage: defineAsyncComponent(() => import("@/components/checkout/CheckoutPage.vue")),
    AuthorInfo,
    AuthorList: defineAsyncComponent(() => import("@/components/author/AuthorList.vue")),
    SubNavBlock,
    FreeSeats: defineAsyncComponent(() => import("@/components/ui/FreeSeats.vue")),
    ProductFilter: defineAsyncComponent(() => import("@/components/product-filter/ProductFilter.vue")),
    ShowHide,
    SvMap: defineAsyncComponent(() => import("@/components/ui/SvMap.vue")),
    SearchPageSearch,
    GaEventScript,
    RegisterButton,
  },
};
const pinia = createPinia();
const headerSearch = createApp(RootComponentHeaderSearch);
headerSearch.use(pinia).mount("#header-search");
const main = createApp(RootComponentMain);
main.use(pinia).mount("#main-content");
