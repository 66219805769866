import axios, { AxiosInstance } from "axios";

const requestverificationtoken = window.localStorage.getItem(
  "requestVerificationToken"
);

const headers = {
  "content-type": "application/json",
  "litium-request-context": JSON.stringify(window.__litium.requestContext),
  ...(requestverificationtoken && { requestverificationtoken }),
};

export const api: AxiosInstance = axios.create({
  baseURL: `https://${window.location.host}/api/`,
  headers,
});
