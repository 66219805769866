(function () {
  const header = document.querySelector("#header");
  const searchButton = document.querySelector(".header__buttons-search");
  const toggleSearch = (e) => {
    e.target.classList.toggle("header__buttons-search--close");
    e.target
      .closest(".header__row-inner")
      .querySelector(".header__row-inner-search")
      .classList.toggle("header__row-inner-search--show");
  };
  if (searchButton) {
    searchButton.addEventListener("click", (e) => toggleSearch(e));
  }
  const toggleSub = (e) => {
    if (window.innerWidth < 900) {
      e.preventDefault();
      const prevShown = document.querySelector(".navbar__menu-item--show");
      if (prevShown && prevShown !== e.target.parentNode) prevShown.classList.remove("navbar__menu-item--show");
      else if (header) header.classList.toggle("header--show-sub-menu");
      e.target.parentNode.classList.toggle("navbar__menu-item--show");
    }
  };

  // const listItemButtons = document.querySelectorAll(
  //   ".navbar__menu-item:not(.navbar__menu-item--mobile) > .navbar__menu-item-select"
  // );

  const listItemButtons = document.querySelectorAll(".navbar__menu-item:not(.navbar__menu-item--mobile) > a");
  if (listItemButtons) {
    listItemButtons.forEach((e) => {
      e.addEventListener("click", (el) => toggleSub(el));
    });
  }
  const listBackButtons = document.querySelectorAll(".navbar__menu-item:not(.navbar__menu-item--mobile) > span");
  if (listBackButtons) {
    listBackButtons.forEach((e) => {
      e.addEventListener("click", (el) => toggleSub(el));
    });
  }

  const menuButton = document.querySelector(".header__buttons-menu");

  const toggleMenu = (e) => {
    e.target.classList.toggle("header__buttons-menu--close");
    const aria = e.target.getAttribute("aria-expanded");
    e.target.setAttribute("aria-expanded", aria.toString() === "false" ? true : false);
    if (header) header.classList.toggle("header--show-menu");
  };
  if (menuButton) {
    menuButton.addEventListener("click", (e) => toggleMenu(e), true);
  }

  const listExpand = document.querySelectorAll(".navbar__categories-item-toggle-btn");
  if (listExpand) {
    listExpand.forEach((e) => {
      e.addEventListener("click", (el) => {
        el.preventDefault();
        const dText = el.target.dataset.text;
        const text = el.target.innerText;
        el.target.innerText = dText;
        el.target.dataset.text = text;
        el.target.closest(".navbar__categories").classList.toggle("navbar__categories--collapsed");
      });
    });
  }
})();
