<script setup lang="ts">
  import { useSearchStore } from "@/store/searchStore";
  import { websiteText } from "@/mixins/websiteTexts";
  import CountyFilter from "../product-filter/CountyFilter.vue";
  import { computed, onMounted, ref } from "vue";
  defineProps<{
    term?: string;
  }>();
  const search = useSearchStore();
  const searchUrl = computed(() => {
    return window.__litium.quickSearchUrl + (search.getQuery.length > 0 ? `?q=${search.getQuery}` : "");
  });
  onMounted(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("g_County")) {
      g_county.value = urlParams.get("g_County");
    }
  
  });
  const g_county = ref<string | null>(null);
</script>
<template>
  <div ref="componentRef" class="search">
    <form role="search" method="get" :action="searchUrl" class="search__form">
      <input v-if="g_county" type="hidden" name="g_County" :value="g_county" />
      <input :value="term" type="search" role="searchbox" class="search__form-input" autocomplete="off" name="q" />
      <CountyFilter :in-search-bar="true"></CountyFilter>
      <button class="search__form-button">
        <span class="search__form-button-text">{{ websiteText("sv.menu.search.text") }}</span>
      </button>
    </form>
  </div>
</template>
<style lang="scss" scoped>
  @use '../../../css/abstracts' as *;
  .search {
    position: relative;

    &__form {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      > * {
        flex-basis: 100%;
        height: to-rem(44px);
      }
      @include medium {
        height: to-rem(44px);
        min-width: to-rem(400px);
        > * {
          flex-basis: unset;
          height: auto;
        }
      }

      &-input {
        padding: 0px 8px;
        width: 100%;
        background: $color-white;
        border: 1px solid $color-border-gray;
        border-radius: 4px;
        @include medium {
          width: 374px;
        }
      }
      &-button {
        border: none;
        min-width: to-rem(50px);
        border-radius: 50px;
        cursor: pointer;
        background-color: transparent;
        display: flex;
        align-items: center;
        padding: to-rem(0 16px);
        justify-content: space-between;
        &::after {
          margin-left: to-rem(8px);
          content: "";
          display: inline-block;
          @include icon;
          @include icon--magnifier;
        }

        background-color: $color-text;
        color: $color-white;
        @include medium {
          min-width: to-rem(70px);
        }
      }
    }
  }
</style>
